import React from 'react';
import classNames from 'classnames';
import { ReactComponent as ChevronLeft } from './chevron_big_left.svg';
import { ReactComponent as ChevronRight } from './chevron_big_right.svg';
import './index.css';

export default function Timeline(props) {
  const {
    stories,
    activeStoryIndex,
    setNextStoryIndex,
  } = props;

  return (
    <div className="timeline">
      <div className="timeline-controls">
        <button
          aria-label="Previous story"
          onClick={() => setNextStoryIndex(activeStoryIndex - 1)}
        >
          <ChevronLeft />
        </button>
        <button
          aria-label="Next story"
          onClick={() => setNextStoryIndex(activeStoryIndex + 1)}
        >
          <ChevronRight />
        </button>
      </div>
      {stories.map((story, index) => (
        <span
          key={story.title}
          className={classNames('timeline-indicator', {
            '--is-active': activeStoryIndex === index,
          })}
        />
      ))}
    </div>
  );
}
