import React from 'react';
import Journey from './Journey';
import Map from './Map';
import { ReactComponent as Logo } from './logo.svg';
import './app.css';

export default function App() {
  const [continent, setContinent] = React.useState(null);

  return (
    <div className="app">
      <div className="logo-container">
        <a href="https://iwd.fullerproject.org/">
          <Logo className="logo" />
        </a>
      </div>
      {!!continent ? (
        <Journey continent={continent} setContinent={setContinent} />
      ) : (
        <Map setContinent={setContinent} />
      )}
    </div>
  );
}
