const data = {
  'NA': [
    {
      country: 'US',
      title: 'How Black Women Fought to Mobilize America’s Voters',
      link: 'https://fullerproject.org/story/black-women-political-power-presidential-election/',
      coverPhoto: 'black-women-political-power-presidential-election.jpg',
      reporter: 'Jessica Washington and Tiffany Arnold, in partnership with The Guardian',
      photoCredit: 'Kai Tsehay / The Fuller Project',
      audio: 'black-women-political-power-presidential-election.mp3',
      quote: '"In America, this election year has not played out in a vacuum. Rather, it has been met with — and compounded by — America’s year of reckoning with police brutality and systemic inequality that has driven even more people to vote."',
    },
    {
      country: 'US',
      title: 'Inmates Witnessed a Suicide Attempt',
      link: 'https://fullerproject.org/story/prison-mental-health-covid-confinement/',
      coverPhoto: 'prison-mental-health-covid-confinement.png',
      reporter: 'Eileen Guo, Originally published in The Washington Post',
      photoCredit: 'Mariyah Espinoza',
      quote: '“People aren’t scared of covid-19, they are scared of the treatment of isolation.” - April Harris, an incarcerated woman who spent a month in medical isolation.',
    },
    {
      country: 'US',
      title: '‘Binary’ Choice for Detained Mothers During COVID: Family Separation or Indefinite Detention',
      link: 'https://fullerproject.org/story/binary-choice-for-detained-mothers-during-covid-family-separation-or-indefinite-detention/',
      coverPhoto: 'binary-choice-for-detained-mothers.jpg',
      reporter: 'Tanvi Misra',
      photoCredit: 'Greg Bulla/Unslpash',
      quote: '“I keep asking for medicine and no one will bring it to us.” - Margarita',
    },
    {
      country: 'US',
      title: 'Pandemic crushes global supply chains, workers at both ends',
      link: 'https://fullerproject.org/story/pandemic-crushes-global-supply-chains-workers-at-both-ends/',
      coverPhoto: 'pandemic-crushes-global-supply-chains.jpg',
      reporter: 'Louise Donovan, in partnership with The Associated Press',
      photoCredit: 'Madeline Tolle/The Fuller Project',
      quote: 'Most of [the women] working in the factories are single parents supporting their families and right now, the industry is facing a total collapse.” - Tšepang Makakole of the National Clothing Textile and Allied Workers Union',
    },
    {
      country: 'MX',
      title: 'Revealed: US Citizen Newborns Sent to Mexico Under Trump-Era Border Ban',
      link: 'https://fullerproject.org/story/revealed-us-citizen-newborns-sent-to-mexico-under-trump-era-border-ban/',
      coverPhoto: 'revealed-us-citizen-newborns-sent-to-mexico.jpg',
      reporter: 'Tanvi Misra, in partnership with the Guardian',
      photoCredit: 'pixabaypexels',
      quote: '“It has become really clear that the right to life protection of the children is about protecting white, Christian children … not about brown children born to immigrant mothers.” - Nicole Ramos, director of Al Otro Lado’s Border Rights Project, a legal services organization for migrants.',
    },
    {
      country: 'BB',
      title: 'Could Reparations From the UK Address Gender Inequality in Barbados? The Prime Minister Thinks So.',
      link: 'https://fullerproject.org/story/could-reparations-from-the-uk-address-gender-inequality-in-barbados-the-prime-minister-thinks-so/',
      coverPhoto: 'could-reparations-from-the-uk.jpg',
      reporter: 'Escher Walcott, in partnership with gal—dem',
      photoCredit: 'Javie Huxley for Gal—Dem',
      photoCreditNoun: 'Illustration',
      quote: '“For us, reparations is not just simply about money but it is also about justice.” - Prime Minister Mia Mottley',
    },
    {
      country: 'PA',
      title: 'Transgender and Trapped in Sex-Based Social Distancing',
      link: 'https://fullerproject.org/story/transgender-sex-social-distancing-panama/',
      coverPhoto: 'transgender-sex-social-distancing-panama.jpg',
      reporter: 'Marta Martinez, in partnership with Foreign Policy',
      photoCredit: 'Kao Rodriguez/Unsplah',
      quote: '“Now with the pandemic, we don’t exist.” - Venus Tejada, the director of the Panamanian Association of Trans People and a transgender woman herself.',
    },
  ],
  'SA': [
    {
      country: 'VE',
      title: 'Targeted for abuse: With borders closed, women fleeing Venezuela face additional threats',
      link: 'https://fullerproject.org/story/targeted-for-abuse-with-borders-closed-women-fleeing-venezuela-face-additional-threats/',
      reporter: 'Marta Martinez, in partnership with CNN',
      coverPhoto: 'targeted-for-abuse-with-borders-closed-women.jpg',
      photoCredit: 'Shutterstock',
      summary: [
        `Colombia's COVID-19 border closure puts Venezuelan migrant women at greater risk of sexual violence and other abuses.`,
        `Lack of legal status deters many Venezuelan migrant women from seeking justice in Colombia.`,
        `Sex trafficking has also increased during the pandemic: The number of foreigners trafficked into Colombia so far this year was already 20% higher as of May than for all of 2019, United Nations Office on Drugs and Crime says. More than 90% of the victims were Venezuelan women.`,
      ],
    },
    {
      country: 'BR',
      title: 'The Brutality of Brazil’s Pandemic Call Centers',
      link: 'https://fullerproject.org/story/brazil-call-centers-covid-women-risk/',
      reporter: 'Jill Langlois, published in Foreign Policy',
      coverPhoto: 'brazil-call-centers-covid-women-risk.jpg',
      photoCredit: 'Tima Miroshnichenko/Pexels)',
      summary: [
        'Call centers are a mainstay of the Brazilian economy—and a growing menace to its workers.',
      ],
    },
  ],
  'EU': [
    {
      country: 'GB',
      title: '‘I Want To Go Home’: Pandemic Exposes Exploitative Conditions Filipina Domestic Workers Face',
      link: 'https://fullerproject.org/story/filipina-domestic-worker-trafficking-pandemic/',
      reporter: 'Corinne Redfern, in partnership with the Guardian',
      coverPhoto: 'filipina-domestic-worker-trafficking-pandemic.jpg',
      photoCredit: 'Shutterstock',
      audio: 'filipina-domestic-worker-trafficking-pandemic.mp3',
      summary: [
        'Filipina domestic workers around the world have always been exposed to abuse and exploitation, but COVID-19 is highlighting just how vulnerable they are.',
        'Despite promises of support from the Philippine government, thousands of Filipina domestic workers are still stranded overseas, with no means of getting home.',
        'Filipina domestic workers say the UK’s immigration policies already enabled abuse, but the pandemic has made their lives worse.',
      ],
    },
    {
      country: 'PL',
      title: 'Poland’s Abortion Rights Protests Lead to a Louder Call for Gender Equity',
      link: 'https://fullerproject.org/story/poland-abortion-rights-protests-gender-equity/',
      coverPhoto: 'poland-abortion-rights-protests-gender-equity.png',
      reporter: 'Paulina Reiter, in partnership with Les Glorieuses',
      photoCredit: 'Agata Nowicka for Les Glorieuses',
      photoCreditNoun: 'Illustration',
      summary: [
        `Poland already has in place one of the strictest abortion laws in Europe. A court ruling in October would further tighten restrictions by only allowing the termination of a pregnancy in cases of incest and rape, and when a mother’s life is at risk.`,
        `Women have been at the forefront of the protest movement denouncing the ruling — the country’s largest protests since the end of Communism 30 years ago. Though the ruling has yet to be implemented, protests still continue months later.`,
        `Emboldened activists say they have expanded their goals and are now setting their sights on abolishing the current government and its policies, which have frequently taken aim at LGBTQIA+ people and have threatened to abandon domestic violence protection measures for women.`,
      ],
    },
    {
      country: 'IT',
      title: 'Threat of Deportation Looms for Migrant Women Detained in Italy, Despite Rights to Legal Protection',
      link: 'https://fullerproject.org/story/despite-legal-rights-threat-of-deportation-looms-for-migrant-women-detained-in-italy/',
      reporter: 'Corinne Redfern, in partnership with Il Post',
      coverPhoto: 'despite-legal-rights-threat-of-deportation.jpg',
      photoCredit: 'Shutterstock',
      quote: '“It’s shocking that the state can be so wickedly efficient with these demographics — like women, who are particularly vulnerable — and who have rights that they are not able to exercise.” - Salvatore Fachile, a lawyer and researcher specializing in human trafficking and migration in Italy.',
    },
  ],
  'AS': [
    {
      country: 'SA',
      title: 'Laid Off and Locked Up: Virus Traps Domestic Workers in Arab States',
      link: 'https://fullerproject.org/story/domestic-workers-saudi-arabia-lebanon-abuse-covid/',
      reporter: 'Louise Donovan and Ben Hubbard, originally published in The New York Times',
      coverPhoto: 'domestic-workers-saudi-arabia-lebanon-abuse-covid.jpg',
      photoCredit: 'Shutterstock',
      audio: 'domestic-workers-saudi-arabia-lebanon-abuse-covid.m4a',
      summary: [
        'The pandemic and economic crises have caused many workers to lose their jobs. Some have been detained, abused, deprived of wages and stranded far from home with nowhere to turn for help.',
      ],
    },
    {
      country: 'IR',
      title: 'Iran’s Nurses Are Martyrs to Trump’s Maximum Pressure',
      link: 'https://fullerproject.org/story/irans-nurses-are-martyrs-to-trumps-maximum-pressure/',
      reporter: 'Negar Mortazavi, originally published in Foreign Policy',
      coverPhoto: 'irans-nurses-are-martyrs-to-trumps-maximum-pressure.jpg',
      photoCredit: 'Fatemeh Bahrami/Anadolu Agency via Getty Images',
    },
    {
      country: 'LB',
      title: 'After Escaping Abuse in the Middle East, Domestic Workers’ Woes Are Far From Over in this Pandemic',
      link: 'https://fullerproject.org/story/domestic-workers-middle-east-after-repatriation/',
      reporter: 'Louise Donovan & Moraa Obiria, in partnership with The Telegraph and Kenya’s Daily Nation',
      coverPhoto: 'domestic-workers-middle-east-after-repatriation.jpg',
      photoCredit: 'Shutterstock',
      quote: '“I thought I would go to Lebanon, buy a piece of land and build a small house for my kids – now my dreams have shattered.” - Wanuji, who also asked to be identified by her middle name out of fear of further stigma.',
    },
    {
      country: 'AF',
      title: '‘They Came to Kill the Mothers.’ After a Devastating Attack on a Kabul Maternity Ward, Afghan Women Face Increased Dangers',
      link: 'https://fullerproject.org/story/they-came-to-kill-the-mothers-after-a-devastating-attack-on-a-kabul-maternity-ward-afghan-women-face-increased-dangers/',
      coverPhoto: 'they-came-to-kill-the-mothers-after-a-devastating-attack-on-a-kabul.jpg',
      photoCredit: 'AP Photo/Rahmat Gul',
      reporter: 'Sophia Jones, Originally published in TIME',
      quote: '“Naturally, women are afraid,” she said. “And they don’t want to go to the maternity wards [for] their pregnancy. But this is very dangerous for the mother and the child and the mortality rate of the mothers and the children will [increase].” - Maryam Bahram Azimi, a gynecologist at the maternity ward in Kabul’s Isteqlal Hospital.',
    },
    {
      country: 'KR',
      title: 'Sex Criminals Use Bitcoin. So Do the Police.',
      link: 'https://fullerproject.org/story/sex-criminals-use-bitcoin-so-do-the-police/',
      reporter: 'Corinne Redfern and Seulki Lee, in partnership with Foreign Policy',
      coverPhoto: 'sex-criminals-use-bitcoin-so-do-the-police.jpg',
      photoCredit: 'Foreign Policy illustration/Getty Images',
      photoCreditNoun: 'Illustration',
      summary: [
        `Cryptocurrencies are increasingly being used to fund child sexual exploitation, creating new opportunities for law enforcement to track down perpetrators.`,
        `Globally, law enforcement agents tasked with investigating child sexual exploitation often lack the training, knowledge and resources to pursue the growing number of operations financed by Bitcoin and other cryptocurrencies.`,
        `This lack of understanding of what cryptocurrency is and what its use in child exploitation looks like often leads anti-trafficking investigators in the U.S. and around the world to reject cases or miss crucial pieces of evidence.`,
      ],
    },
    {
      country: 'PH',
      title: '‘I’m scared every damn day’: In the Philippines, violence shadows trans lives',
      link: 'https://fullerproject.org/story/im-scared-every-damn-day-in-the-philippines-violence-shadows-trans-lives/',
      reporter: 'Corinne Redfern and Seulki Lee, in partnership with Tatler Philippines',
      coverPhoto: 'im-scared-every-damn-day-in-the-philippines.jpg',
      photoCredit: 'Pau Villanueva for The Fuller Project',
      audio: 'im-scared-every-damn-day-in-the-philippines.mp3',
      summary: [
        `At least 50 transgender or gender nonbinary individuals have been murdered across the archipelago since 2010 — but the real death toll is likely much higher.`,
        `There is currently no national legislation that would protect LGBTQ individuals from discrimination in the Philippines, although the Sexual Orientation and Gender Identity and Expression (SOGIE) Bill would see those who discriminate against LGBTQ individuals in the Philippines face fines or jail time.`,
        `Whatever the circumstances of their murder, victims appear to have little chance of recourse: in the past six years, the Philippine National Police has publicly confirmed investigations into the murders of eight trans women.`,
      ],
    },
    {
      country: 'IN',
      title: 'As India’s Credit Sector Falters, So Do Women’s Livelihoods',
      link: 'https://fullerproject.org/story/women-will-be-the-first-to-fall-as-indias-rural-credit-sector-unravels/',
      reporter: 'Mahima Jain, in partnership with the Scroll in India and in Der Spiegel in Germany',
      coverPhoto: 'women-will-be-the-first-to-fall-as-indias.jpg',
      photoCredit: `Nishika D'Silva for The Fuller Project`,
      photoCreditNoun: 'Illustration',
      summary: [
        `The microfinance sector caters to income-insecure women with the promise of empowerment but most often women borrow to fund men’s assets, household expenses.`,
        `The interest rates are high, but these previously “unbanked” women do not have other options to access credit.`,
        `Lenders are now over-cautious since the repayment rates of previous loans have fallen. In India, loan disbursement by the microfinance sector fell by 97% in the April-June quarter compared to prior year. This leaves income-insecure families with no access to credit in times of need, particularly when incomes are volatile due to COVID-19. This will result in the resurrection of unregulated loan sharks.`,
      ],
    },
    {
      country: 'BD',
      title: 'This woman is fighting to save Bangladesh’s oldest brothel',
      link: 'https://fullerproject.org/story/women-trafficked-brothel-bangladesh/',
      reporter: 'Corinne Redfern and Ali Ahsan, in partnership with The Economist’s 1843 Magazine',
      coverPhoto: 'women-trafficked-brothel-bangladesh.jpg',
      photoCredit: 'Mahmud Hossain Opu / 1843 Magazine',
      quote: '“Happiness is helping others.” - Monowara on acting maternally towards younger women.',
    },
  ],
  'AF': [
    {
      country: 'ZW',
      title: 'In a changed mindset, new Zimbabwe law offers pregnant teens the promise of education',
      link: 'https://fullerproject.org/story/in-a-changed-mindset-new-zimbabwe-law-offers-pregnant-teens-the-promise-of-education/',
      reporter: 'Farai Shawn Matiashe, in partnership with Marie Claire UK and ZimLive.com',
      coverPhoto: 'in-a-changed-mindset-new-zimbabwe-law.png',
      photoCredit: 'Ruramai Musekiwa for The Fuller Project',
      photoCreditNoun: 'Illustration',
      summary: [
        `Before the pandemic, pregnant girls in Zimbabwe were not allowed to stay in school.`,
        `But the country’s nationwide lockdown kept children out of school for six months, resulting in skyrocketing rates of teen pregnancy and child marriage, and leading the government to reverse its earlier ban.`,
        `The move, which has rippled across the African continent, was heralded by child education advocates as significant for the lives of Zimbabwean girls and women who now find it easier to pursue education and economic advancement.`,
      ],
    },
    {
      country: 'UG',
      title: 'A Transport Ban in Uganda Means Women are Trapped at Home with Their Abusers',
      link: 'https://fullerproject.org/story/a-transport-ban-in-uganda-means-women-are-trapped-at-home-with-their-abusers/',
      reporter: 'Louise Donovan, originally published in CNN International',
      coverPhoto: 'a-transport-ban-in-uganda-means-women.jpg',
      photoCredit: 'Shutterstock',
      quote: `“Violence against women is accepted here” - Josephine Aparo, Senior Coordinator at International Justice Mission`,
    },
    {
      country: 'KE',
      title: '‘We know it’s the work of quack doctors’: Pandemic fans the flames of backstreet abortions in Kenya',
      link: 'https://fullerproject.org/story/abandoned-foetuses-kenya-abortion/',
      reporter: 'Louise Donovan and Nasibo Kabale, in partnership with the Telegraph and Kenya’s Daily Nation',
      coverPhoto: 'abandoned-foetuses-kenya-abortion.jpg',
      photoCredit: 'Khadija Farah for The Fuller Project',
      summary: [
        `The issue of abandoned newborns in Kenya is not new, but local service providers are reporting an uptick during the coronavirus pandemic.`,
        `Many cases go unreported because there’s no national centralized data system to keep track of the total.`,
        `The cost of a safe abortion procedure is out of reach for many women — nearly 90 percent of Kenyans live on less than £4 a day.`,
        `Almost two thirds of pregnancies among women 15–19 are unintended each year, according to the Guttmacher Institute.`,
      ],
    },
    {
      country: 'NG',
      title: 'Women Were Key to Eradicating Wild Polio in Africa. But Can They Do the Same for COVID?',
      link: 'https://fullerproject.org/story/africa-women-eradicate-polio-covid/',
      reporter: 'Jessica Washington and Louise Donovan, in partnership with Marie Claire UK and Kenya’s the Nation',
      coverPhoto: 'africa-women-eradicate-polio-covid.png',
      photoCredit: 'Sarah Ijangolet for The Fuller Project',
      photoCreditNoun: 'Illustration',
      summary: [
        'How do governments and the wider medical community successfully immunise the entire world against COVID-19? The answer might just lie with women, who played an enormous role in eradicating wild polio across Africa.',
      ],
    },
    {
      country: 'NG',
      title: 'Isolated in Rural Nigeria—and Waiting for America to Vote',
      link: 'https://fullerproject.org/story/rural-nigeria-voting-america-policy/',
      reporter: 'Shola Lawal and Sophia Jones, in partnership with Foreign Policy',
      coverPhoto: 'rural-nigeria-voting-america-policy.jpg',
      photoCredit: 'Shola Lawal for Foreign Policy and The Fuller Project',
      summary: [
        'Across much of the world—including one remote Nigerian village—the availability of family planning will largely depend on the outcome of the U.S. presidential election.',
      ],
    },
  ],
};

export default data;
