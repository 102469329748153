import React from 'react';
import { ReactComponent as Continents } from './continents.svg';
import './index.css';

const continents = ['NA', 'SA', 'EU', 'AS', 'AF'];

export default function Map(props) {
  const { setContinent } = props;

  const mapRef = React.useRef(null);
  const containerRef = React.useRef(null);

  const [targetContinent, setTargetContinent] = React.useState(null);

  React.useEffect(() => {
    continents.forEach((key) => {
      const element = document.getElementById(key);
      element.classList.add('continent');

      element.addEventListener('click', function() {
        setTargetContinent(key);
      });

      element.addEventListener('mouseenter', function() {
        element.classList.add('--continent-hover');
      });

      element.addEventListener('mouseleave', function() {
        element.classList.remove('--continent-hover');
      });
    });
  }, [setTargetContinent]);

  React.useEffect(() => {
    function updateMapSize() {
      if (!mapRef.current || !containerRef.current) {
        return;
      }

      const { width, height } = containerRef.current.getBoundingClientRect();
      mapRef.current.style.width = width * 1.25;
      mapRef.current.style.height = height * 1.25;
    }

    updateMapSize();

    window.addEventListener('resize', updateMapSize);
    return () => window.removeEventListener('resize', updateMapSize);
  }, []);

  React.useEffect(() => {
    let timeoutId = null;

    if (targetContinent) {
      mapRef.current.classList.add('--map-fade')
      timeoutId = setTimeout(() => setContinent(targetContinent), 500);
    }

    return () => clearTimeout(timeoutId);
  }, [targetContinent, setContinent]);

  return (
    <React.Fragment>
      <div ref={containerRef} className="map-container">
        {!targetContinent && (
          <div className="map-prompt-wrapper">
            <span className="map-prompt">
              <p>Select a continent to dive into The Fuller Project's reporting.</p>
            </span>
          </div>
        )}
        <Continents ref={mapRef} />
      </div>
      <div className="map-background">
        <video autoPlay muted loop>
          <source src="/videos/background-loop.mp4" type="video/mp4" />
          <source src="/videos/background-loop.mp4" type="video/webm" />
        </video>
      </div>
    </React.Fragment>
  );
}
