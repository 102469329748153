import React from 'react';
import AudioSpectrum from 'react-audio-spectrum';
import { ReactComponent as PlayIcon } from './play.svg';
import { ReactComponent as PauseIcon } from './pause.svg';
import './index.css';

const autoPlayDefault = true;

export default function StoryAudio(props) {
  const { audioSrc } = props;

  const [isDesktop, setIsDesktop] = React.useState(false);
  const [isPlaying, setIsPlaying] = React.useState(autoPlayDefault);

  const audioElementRef = React.useRef(null);

  React.useEffect(() => {
    function onResize() {
      const { matches } = window.matchMedia('(min-width: 1024px)');
      setIsDesktop(matches);
    }

    onResize();
    window.addEventListener('resize', onResize);

    return () => window.removeEventListener('resize', onResize);
  }, []);

  React.useEffect(() => {
    if (audioElementRef.current) {
      audioElementRef.current[isPlaying ? 'play' : 'pause']();
    }
  }, [
    isPlaying,
  ]);

  React.useEffect(() => {
    if (audioElementRef.current) {
      audioElementRef.current.volume = 0.25;
    }
  }, [
    audioSrc,
    isDesktop,
  ]);

  if (!audioSrc || !isDesktop) {
    return null;
  }

  const ButtonIcon = isPlaying ? PauseIcon : PlayIcon;

  return (
    <div className="story-audio">
      <div className="story-audio-layout">
        <audio
          id="audio-element"
          ref={audioElementRef}
          src={audioSrc}
          autoPlay={autoPlayDefault}
          loop
        />
        <button
          aria-label={isPlaying ? 'Pause audio' : 'Play audio'}
          onClick={() => setIsPlaying(!isPlaying)}
        >
          <ButtonIcon width="36px" height="36px" />
        </button>
        <AudioSpectrum
          width={128}
          height={36}
          audioId="audio-element"
          capColor="#00aabc"
          capHeight={2}
          meterWidth={2}
          meterCount={512}
          meterColor={[
            {stop: 0, color: '#00aabc'},
            {stop: 0.5, color: '#00cde3'},
            {stop: 1, color: '#0be8ff'}
          ]}
          gap={4}
        />
      </div>
    </div>
  );
}
