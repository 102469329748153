import React from 'react';
import Typewriter from 'typewriter-effect';
import classNames from 'classnames';
import Timeline from './Timeline';
import StoryAudio from './StoryAudio';
import data from './data';
import './index.css';

const StoryGlobe = React.lazy(() => import('./StoryGlobe'));

export default function Journey(props) {
  const { continent, setContinent } = props;

  const [activeStoryIndex, setActiveStoryIndex] = React.useState(0);
  const [nextStoryIndex, setNextStoryIndex] = React.useState(null);

  React.useEffect(() => {
    let timeoutId = null;

    if (nextStoryIndex !== null && nextStoryIndex !== activeStoryIndex) {
      timeoutId = setTimeout(() => {
        setActiveStoryIndex(nextStoryIndex);
        setNextStoryIndex(null);
      }, 500);
    } else {
      setNextStoryIndex(null);
    }

    return () => clearTimeout(timeoutId);
  }, [
    activeStoryIndex,
    nextStoryIndex,
    setNextStoryIndex,
    setActiveStoryIndex,
  ]);

  React.useEffect(() => {
    if (nextStoryIndex < 0 || nextStoryIndex >= data[continent].length) {
      setContinent(null);
    }
  }, [
    nextStoryIndex,
    continent,
    setContinent,
  ]);

  React.useEffect(() => {
    let direction = 0;
    let timeoutId = null;

    function onWheel(event) {
      const { deltaY } = event;

      if (deltaY >= 50) direction = 1;
      if (deltaY <= -50) direction = -1;

      if (!!direction) {
        clearTimeout(timeoutId);

        timeoutId = setTimeout(() => {
          setNextStoryIndex(activeStoryIndex + direction);
          direction = 0;
        }, 50);
      }
    }

    window.addEventListener('wheel', onWheel);
    return () => {
      window.removeEventListener('wheel', onWheel);
      clearTimeout(timeoutId);
    };
  }, [
    activeStoryIndex,
    setNextStoryIndex,
  ]);

  const isTransitioning = nextStoryIndex !== null;

  const story = data[continent][activeStoryIndex];
  const journeyClassNames = classNames('journey', { '--journey-fade-out': isTransitioning });

  const backgroundStyle = story.coverPhoto
    ? { backgroundImage: `url(/photos/${story.coverPhoto})` }
    : { background: 'linear-gradient(90deg, rgba(211,239,241,1) 0%, rgba(0,153,169,1) 100%)' };

  const byline = `by ${story.reporter}.${story.photoCredit ? ` ${story.photoCreditNoun || 'Photo'} Credits: ${story.photoCredit}.` : ''}`;

  return (
    <div className={journeyClassNames}>
      <div className="journey-background" style={backgroundStyle} />
      <div className="journey-overlay" />
      <div className="journey-globe">
        <React.Suspense fallback={<React.Fragment />}>
          <StoryGlobe country={story.country} />
        </React.Suspense>
      </div>
      <div className="journey-content">
        {!isTransitioning && (
          <Typewriter
            wrapperClassName="story-title"
            options={{
              wrapperClassName: 'Typewriter__wrapper story-title',
              delay: 75,
            }}
            onInit={(typewriter) => typewriter.typeString(story.title).start()}
          />
        )}
        <p className="story-byline">{byline}</p>
        {story.quote && (
          <blockquote className="story-quote">
            {story.quote}
          </blockquote>
        )}
        {story.summary && (
          <div className="story-summary">
            <h3>what you need to know</h3>
            <ul className="story-summary-list">
              {story.summary.map((line) => <li key={line}>{line}</li>)}
            </ul>
          </div>
        )}
        <a href={story.link} className="story-link">Read the story on FullerProject.org</a>
      </div>
      {story.audio && (
        <div className="journey-audio">
          <StoryAudio audioSrc={`/audio/${story.audio}`} />
        </div>
      )}
      <Timeline
        stories={data[continent]}
        activeStoryIndex={activeStoryIndex}
        setNextStoryIndex={setNextStoryIndex}
      />
    </div>
  );
}
